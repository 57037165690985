import { useState } from "react";
import { Button} from "semantic-ui-react";
import { Transaction } from "../interfaces/Transaction";
import { TransactionSummaryRow } from "./AggregatedTable";

interface Props {
    data: Transaction[];
    loading: boolean;
    dateFrom: Date | null;
    dateTo: Date | null;
}

interface DisplayTx {
    priceMin: number;
    priceMax: number;
    url: string;
    sales: number;
    total: number;
    partner: string;
    currency: string;
    title: string;
}

function generateFile(transactions : TransactionSummaryRow[]) : string {
    return `Artikel, Lägsta pris, Högsta pris,Antal köp,Totala intäkter,Valuta,Partner,Titel\n${
        transactions.map(toDisplayTx).map(row => toRow(row).slice(0).join(",")).join('\n')
    }`;
}

const toDateString = (date: Date) => `${date.toLocaleDateString()}#${date.toLocaleTimeString()}`

const toRow = (dtx: DisplayTx) => {
    return [dtx.url, dtx.priceMin, dtx.priceMax, dtx.sales, dtx.total, dtx.currency, dtx.partner, encodeTitle(dtx.title)];
}
const toDisplayTx = (summary : TransactionSummaryRow) : DisplayTx => {
    return {
        url: summary.url,
        sales: summary.sales,
        total: summary.total,
        partner: new URL(summary.url).host,
        priceMin: summary.priceMin,
        priceMax: summary.priceMax,
        currency: "sek",
        title: summary.title ? summary.title : summary.url
    }
}
const encodeTitle = (title : string) : string => {
    return JSON.stringify(title);
}

//https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
}

export function AggregatedCsvExportButton({data, loading, dateFrom, dateTo} : Props) {
    const [working, setWorking] = useState(false);
    
    let summaryMap = new Map<string, TransactionSummaryRow>();
    data.forEach(sale => {
        if (summaryMap.has(sale.url)) {
            summaryMap.get(sale.url)!.sales += 1;
            summaryMap.get(sale.url)!.total += sale.price;
            summaryMap.get(sale.url)!.priceMax = Math.max(summaryMap.get(sale.url)!.priceMax, sale.price);
            summaryMap.get(sale.url)!.priceMin = Math.min(summaryMap.get(sale.url)!.priceMin, sale.price);
        } else {
            summaryMap.set(sale.url, {
                title: sale.title,
                priceMin: sale.price,
                priceMax: sale.price,
                url: sale.url,
                sales: 1,
                total: sale.price,
                partner: sale.partner
            })
        }
    });
    let summary = Array.from(summaryMap.values());
    summary.sort((a, b) => b.total - a.total);


    let showButton = dateFrom && dateTo && (data.length > 0);

    function generateCsv() {
        setWorking(true);
        
        let filename = `export-${toDateString(dateFrom!)}--${toDateString(dateTo!)}-aggregated.csv`;
        let content = generateFile(summary);
        download(filename, content);

        setWorking(false);
    }

    return showButton ? <Button primary className="csvButton" loading={working || loading} onClick={generateCsv}>Hämta CSV</Button> : <></>;
}
