import { useState } from "react";
import { Button} from "semantic-ui-react";
import { Transaction } from "../interfaces/Transaction";

interface Props {
    data: Transaction[];
    loading: boolean;
    dateFrom: Date | null;
    dateTo: Date | null;
}

interface DisplayTx {
    resourceId: string;
    partner: string;
    type: "card"|"swish";
    price: number;
    currency: string;
    ts: number;
    title: string;
}

function generateFile(transactions : Transaction[]) : string {
    return `Datum,Tid,Artikel,Pris (hundradel),Valuta,Metod,Partner,Titel\n${
        transactions.map(toDisplayTx).map(row => toRow(row).slice(0).join(",")).join('\n')
    }`;
}

const toDateString = (date: Date) => `${date.toLocaleDateString()}#${date.toLocaleTimeString()}`

const toYYYYMMDD = (ts: number) => {
    const d = new Date(ts);
    return d.toLocaleDateString();
}
const toHHMM = (ts: number) => {
    const d = new Date(ts);
    return d.toLocaleTimeString();
}
const toRow = (dtx: DisplayTx) => {
    return [toYYYYMMDD(dtx.ts), toHHMM(dtx.ts), dtx.resourceId, dtx.price, dtx.currency, dtx.type, dtx.partner, encodeTitle(dtx.title)]
}
const toDisplayTx = (transaction : Transaction) : DisplayTx => {
    return {
        resourceId: transaction.url,
        ts: transaction.date.getTime(),
        type: transaction.type as "card"|"swish",
        partner: new URL(transaction.url).host,
        price: transaction.price,
        currency: "sek",
        title: transaction.title ? transaction.title : transaction.url
    }
}
const encodeTitle = (title : string) : string => {
    return JSON.stringify(title);
}

//https://ourcodeworld.com/articles/read/189/how-to-create-a-file-and-generate-a-download-with-javascript-in-the-browser-without-a-server
function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
}

export function CsvExportButton({data, loading, dateFrom, dateTo} : Props) {
    const [working, setWorking] = useState(false);

    let showButton = dateFrom && dateTo && (data.length > 0);

    function generateCsv() {
        setWorking(true);
        
        let filename = `export-${toDateString(dateFrom!)}--${toDateString(dateTo!)}-plain.csv`;
        let content = generateFile(data);
        download(filename, content);

        setWorking(false);
    }

    return showButton ? <Button primary className="csvButton" loading={working || loading} onClick={generateCsv}>Hämta CSV</Button> : <></>;
}
