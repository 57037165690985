import { useState } from "react";
import { Transaction } from "../interfaces/Transaction"
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, getTransactions } from "../firebase";

export function useTransactions() : [Transaction[], (startDate: Date, endDate: Date, shouldFetchTitles?: boolean) => void, boolean] {
    const [user] = useAuthState(auth);
    
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [loading, setLoading] = useState(false);


    async function fetchTransactions(startDate : Date, endDate : Date, shouldFetchTitles: boolean = false) {
        if (!user) return;

        let userClaims = (await user.getIdTokenResult()).claims;
        if (!userClaims) return;
        
        let partner = userClaims.partner;
        let fullAccess = userClaims.fullAccess;
        if (!partner && !fullAccess) return;
        if (!fullAccess) fullAccess = false;

        setLoading(true);
        let fetchedTransactions = await getTransactions(startDate, endDate, partner, fullAccess);
        setTransactions(fetchedTransactions);
        setLoading(false);
    }

    return [transactions, fetchTransactions, loading]
}