import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth, sendResetEmail } from '../firebase';
import { useEffect, useState } from 'react';
import quiqlyLogo from '../unseald.svg';
import './Login.css';
import { Button, Input } from 'semantic-ui-react';

export default function Reset() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    useEffect(() => {
        if (loading) {
            return console.log("loading", loading);
        }
        if (user) {
            navigate("/dashboard")
        }
    }, [user, loading, navigate]);


    return <>
        <Link to="/"><img src={quiqlyLogo} alt="Unseald" className="landingLogo"></img></Link>
        {emailSent ? 'Ett email har skickats till din address med instruktioner för att återställa lösenordet.' : <>
            <h1 className="title">Återställ lösenord</h1>
            <div className="loginControls">
                <Input
                    attached
                    onChange={(e) => setEmail(e.target.value)}
                    icon='at'
                    iconPosition='left'
                    placeholder="Epostaddress"
                />
                <Button loading={loading} primary onClick={() => {
                    sendResetEmail(email);
                    setEmailSent(true);
                }}>Återställ lösenord</Button>
            </div></>}
    </>;
}