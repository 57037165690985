/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataKeyCreationResult } from '../models/DataKeyCreationResult';
import type { Offer } from '../models/Offer';
import type { PaymentCreationParams } from '../models/PaymentCreationParams';
import type { PaymentUpdateParams } from '../models/PaymentUpdateParams';
import type { PaymentWithTokenResponse } from '../models/PaymentWithTokenResponse';
import type { ResetPasswordRequestBody } from '../models/ResetPasswordRequestBody';
import type { Resource } from '../models/Resource';
import type { ResourceId } from '../models/ResourceId';
import type { ResourceResponseMeta } from '../models/ResourceResponseMeta';
import type { ResumeResponse } from '../models/ResumeResponse';
import type { Transaction } from '../models/Transaction';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static resetPassword(
        requestBody: ResetPasswordRequestBody,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param secret
     * @returns void
     * @throws ApiError
     */
    public static verifyAccount(
        secret: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/verify/{secret}',
            path: {
                'secret': secret,
            },
        });
    }

    /**
     * @param requestBody
     * @param authorization
     * @param xPayToken
     * @returns PaymentWithTokenResponse Ok
     * @throws ApiError
     */
    public static listPayments(
        requestBody: ResourceId,
        authorization?: string,
        xPayToken?: string,
    ): CancelablePromise<Array<PaymentWithTokenResponse>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/list',
            headers: {
                'Authorization': authorization,
                'X-PAY-TOKEN': xPayToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param authorization
     * @param xPayToken
     * @returns PaymentWithTokenResponse Ok
     * @throws ApiError
     */
    public static getPayment(
        uuid: string,
        authorization?: string,
        xPayToken?: string,
    ): CancelablePromise<PaymentWithTokenResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'Authorization': authorization,
                'X-PAY-TOKEN': xPayToken,
            },
        });
    }

    /**
     * @param uuid
     * @param requestBody
     * @param authorization
     * @param xPayToken
     * @returns PaymentWithTokenResponse
     * @throws ApiError
     */
    public static updatePayment(
        uuid: string,
        requestBody: PaymentUpdateParams,
        authorization?: string,
        xPayToken?: string,
    ): CancelablePromise<PaymentWithTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'Authorization': authorization,
                'X-PAY-TOKEN': xPayToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param uuid
     * @param authorization
     * @param xPayToken
     * @returns DataKeyCreationResult Ok
     * @throws ApiError
     */
    public static getPaymentCode(
        uuid: string,
        authorization?: string,
        xPayToken?: string,
    ): CancelablePromise<DataKeyCreationResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/{uuid}/resource-code',
            path: {
                'uuid': uuid,
            },
            headers: {
                'Authorization': authorization,
                'X-PAY-TOKEN': xPayToken,
            },
        });
    }

    /**
     * @param uuid
     * @param authorization
     * @param xPayToken
     * @returns DataKeyCreationResult Ok
     * @throws ApiError
     */
    public static createResumeToken(
        uuid: string,
        authorization?: string,
        xPayToken?: string,
    ): CancelablePromise<DataKeyCreationResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/{uuid}/resume-code',
            path: {
                'uuid': uuid,
            },
            headers: {
                'Authorization': authorization,
                'X-PAY-TOKEN': xPayToken,
            },
        });
    }

    /**
     * @param requestBody
     * @param authorization
     * @returns PaymentWithTokenResponse Ok
     * @throws ApiError
     */
    public static createPayment(
        requestBody: PaymentCreationParams,
        authorization?: string,
    ): CancelablePromise<PaymentWithTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payments',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param token
     * @returns any Ok
     * @throws ApiError
     */
    public static getSwishQr(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payments/swish-qr/{token}',
            path: {
                'token': token,
            },
        });
    }

    /**
     * @param requestBody
     * @param authorization
     * @returns Transaction Ok
     * @throws ApiError
     */
    public static getTransactions(
        requestBody: {
            endDate: number;
            startDate: number;
        },
        authorization?: string,
    ): CancelablePromise<Array<Transaction>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/dashboard/transactions',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param authorization
     * @returns Transaction Ok
     * @throws ApiError
     */
    public static getMyTransactions(
        authorization?: string,
    ): CancelablePromise<Array<Transaction>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard/my-transactions',
            headers: {
                'Authorization': authorization,
            },
        });
    }

    /**
     * @param url
     * @returns any Ok
     * @throws ApiError
     */
    public static getPreview(
        url: string,
    ): CancelablePromise<{
        url: string;
        title: string;
        publisher: string;
        image: string;
        description: string;
        date: string;
        author: string;
        siteName: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/dashboard/preview',
            query: {
                'url': url,
            },
        });
    }

    /**
     * @param secret
     * @returns any Ok
     * @throws ApiError
     */
    public static exchangeResourceToken(
        secret: string,
    ): CancelablePromise<(Resource & ResourceResponseMeta)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resource/{secret}',
            path: {
                'secret': secret,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Offer Ok
     * @throws ApiError
     */
    public static getOffer(
        requestBody: ResourceId,
    ): CancelablePromise<Offer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/offer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param secret
     * @returns ResumeResponse Ok
     * @throws ApiError
     */
    public static exchangeResumeToken(
        secret: string,
    ): CancelablePromise<ResumeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/resume/{secret}',
            path: {
                'secret': secret,
            },
        });
    }

}
