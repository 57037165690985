import { useEffect, useState } from "react";
import { AggregatedTable } from "./AggregatedTable";
import DatePicker, { registerLocale } from "react-datepicker";
import './Dashboard.css';
import "react-datepicker/dist/react-datepicker.css";
import { Statistics } from "./Statistics";
import { TransactionsTable } from "./TransactionsTable";
import quiqlyLogo from '../unseald.svg';
import { useTransactions } from "../hooks/useTransactions";
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logoutFirebase } from "../firebase";
import { useNavigate } from 'react-router-dom';
import { CsvExportButton } from "./CsvExport";
import { isLastInMonth, lastOfMonth } from "../utility/DateUtil";
import sv from "date-fns/locale/sv";
import { AggregatedCsvExportButton } from "./AggregatedCsvExport";
import { Button } from "semantic-ui-react";

registerLocale("sv", sv);

function Dashboard() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const [init, setInit] = useState(true);

    const [transactions, fetchTransactions, loadingTransactions] = useTransactions();
    const [prevTransactions, fetchPreviousTransactions, loadingPreviousTransactions] = useTransactions();

    const [dateStart, setDateStart] = useState<Date | null>(null);
    const [dateEnd, setDateEnd] = useState<Date | null>(null);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading, navigate]);

    useEffect(() => {
        if (init && user) {
            let defaultStart = getStartDate(new Date());
            let defaultEnd = new Date();
            setDateStart(defaultStart);
            setDateEnd(defaultEnd);
            let [previousPeriodStartDate, previousPeriodEndDate] = getPreviousDateRange([defaultStart, defaultEnd]);
            fetchTransactions(defaultStart, defaultEnd, true);
            fetchPreviousTransactions(previousPeriodStartDate, previousPeriodEndDate);
            setInit(false);
        }
    }, [fetchPreviousTransactions, fetchTransactions, init, user]);

    function getStartDate(date: Date): Date {
        let startDate = new Date();
        startDate.setDate(date.getDate() - 14);
        console.log("Assuming 14 days before", date, "is", startDate);
        return startDate;
    }
    function setDateRanges([startDate, endDate]: [Date, Date]) {
        if (startDate && endDate) {
            let [previousPeriodStartDate, previousPeriodEndDate] = getPreviousDateRange([startDate, endDate]);
            setDateStart(startDate);
            setDateEnd(endDate);
            fetchTransactions(startDate, endDate, true);
            fetchPreviousTransactions(previousPeriodStartDate, previousPeriodEndDate);
        } else if (startDate) {
            setDateStart(startDate);
            setDateEnd(null);
        }
    }
    function getPreviousDateRange([startDate, endDate]: [Date, Date]): [Date, Date] {
        let inPreviousMonth = (date: Date) => {
            let prevDate = new Date(date.getTime());
            prevDate.setMonth(date.getMonth() - 1);
            return prevDate;
        };
        let prevStart = inPreviousMonth(startDate);
        let prevEnd = inPreviousMonth(endDate);

        if (isLastInMonth(endDate) && startDate.getDate() === 1) {
            // edge case: if a full month is selected -> compare with previous full month
            prevEnd = lastOfMonth(prevStart);
        }

        console.log("Previous range would be from", prevStart, "to", prevEnd);
        return [prevStart, prevEnd];
    }

    return <div className="dashboard">
        <div className="header">
            <img src={quiqlyLogo} alt="Unseald" className="logo"></img>
            <div className="logoutBox">
                <span className="username">{user?.displayName}</span>
                <Button primary className="logout" onClick={logoutFirebase} >Logga ut</Button>
            </div>
        </div>
        <div className="selection">
            <DatePicker selectsRange className="datepicker" popperProps={{ strategy: 'fixed' }} locale="sv"
                startDate={dateStart}
                endDate={dateEnd}
                onChange={setDateRanges}
            />
        </div>
        <Statistics transactions={transactions} previousTransactions={prevTransactions} loading={loadingTransactions} loadingPrevious={loadingPreviousTransactions} />
        <div className="tables">
            <div>
                <h3>Artikeltoppen</h3>
                <AggregatedTable sales={transactions} loading={loadingTransactions} />
                <AggregatedCsvExportButton data={transactions} loading={loadingTransactions} dateFrom={dateStart} dateTo={dateEnd}></AggregatedCsvExportButton>
            </div>
            <div>
                <h3>Historik</h3>
                <TransactionsTable sales={transactions} loading={loadingTransactions} />
                <CsvExportButton data={transactions} loading={loadingTransactions} dateFrom={dateStart} dateTo={dateEnd}></CsvExportButton>
            </div>
        </div>
    </div>;
}

export default Dashboard;