import { Transaction } from "../interfaces/Transaction";
import { Placeholder, Table } from 'semantic-ui-react';
import dateFormat from "dateformat";

interface Props {
    sales: Transaction[];
    loading: boolean;
}

export function TransactionsTable({ sales, loading }: Props) {
    let sortedSales = [...sales];
    sortedSales.sort((a, b) => b.date.getTime() - a.date.getTime());
    return <div className="tableWrapper">
        <Table celled striped unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Artikel</Table.HeaderCell>
                    <Table.HeaderCell>Pris SEK</Table.HeaderCell>
                    <Table.HeaderCell>Datum</Table.HeaderCell>
                    <Table.HeaderCell>Tid</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body className="transactionBody scrolling content">
                {
                    loading ?
                        <Table.Row>
                            <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                            <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                            <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                            <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                        </Table.Row>
                        : sortedSales.map(sale => {
                            return <Table.Row key={sale.id}>
                                <Table.Cell><a href={sale.url} target="_blank" rel="noreferrer">{sale.title || sale.url}</a></Table.Cell>
                                <Table.Cell>{sale.price / 100}</Table.Cell>
                                <Table.Cell>{dateFormat(sale.date, "yyyy-mm-dd")}</Table.Cell>
                                <Table.Cell>{dateFormat(sale.date, "HH:MM:ss")}</Table.Cell>
                            </Table.Row>;
                        })
                }
            </Table.Body>
        </Table>
    </div>;
}