import { useAuthState } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth, logoutFirebase, signInWithEmail, signInWithGoogle } from '../firebase';
import { useEffect, useState } from 'react';
import quiqlyLogo from '../unseald.svg';
import './Login.css';
import { Button, Input, Message } from 'semantic-ui-react';

export default function Login() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loginLoading, setLoginLoading] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [loginPermissionError, setLoginPermissionError] = useState(false);

    useEffect(() => {
        if (loading) {
            console.log("loading", loading);
        }
        if (user) {
            setLoginLoading(false);
            user.getIdTokenResult().then(userToken => {
                let userClaims = userToken.claims;
                if (!!userClaims) {
                    let anyAccess = (userClaims.partner || userClaims.fullAccess);
                    if (userClaims.dashboard && anyAccess) {
                        navigate("/dashboard");
                        return;
                    }
                }
                logoutFirebase();
                setLoginPermissionError(true);
            });
        }
    }, [user, loading, navigate]);

    async function signIn() {
        try {
            setLoginLoading(true);
            setLoginError(false);
            await signInWithEmail(email, password);
        } catch (err) {
            setLoginLoading(false);
            setLoginError(true);
        }
    }

    return <>
        <Link to="/"><img src={quiqlyLogo} alt="Unseald" className="landingLogo"></img></Link>
        <h1 className="title">Dashboard</h1>
        <div className="loginControls">
            {loginError && <Message warning>Felaktigt användarnamn eller lösenord.</Message>}
            {loginPermissionError && <Message warning>Vänligen kontakta support angående dashboard-access...</Message>}
            <Input
                onChange={(e) => setEmail(e.target.value)}
                error={loginError}
                icon='at'
                iconPosition='left'
                placeholder="Email-address"
            />
            <Input
                onChange={(e) => setPassword(e.target.value)}
                error={loginError}
                type='password'
                icon='lock'
                iconPosition='left'
                placeholder="Lösenord" />
            <Link to="/reset" className="bitSmall">Glömt lösenordet?</Link>
            <Button primary onClick={signIn} loading={loginLoading} className="loginButton">Logga in</Button>
            <div className="eller">eller</div>
            <Button onClick={signInWithGoogle} loading={loginLoading} className="loginButton googleButton">Logga in med Google</Button>
        </div>
    </>;
}