import { ReactElement } from "react";
import { StatisticData } from "./Statistics";
import { Icon, Loader, Popup } from "semantic-ui-react";

interface Props {
    data: StatisticData;
    loading: boolean;
    loadingPrevious: boolean;
}

export function Statistic({data, loading, loadingPrevious} : Props) {
    function formatDifference(difference : number) : ReactElement {
        if (difference === 0) return <></>;
        let positive = difference > 0;
        return <div className="difference">
            {positive? <Icon name="angle up" /> : <Icon name="angle down" />}
            {Math.abs(difference).toFixed(2) + "%"}
            <Popup basic position='bottom center' content='Jämfört med föregående period' trigger={<Icon name="info circle" className="infoIcon"></Icon>} />
        </div>;
    }

    return <div className="statistic">
        { loading ? <Loader active /> : <>
            {data.label}
            <div className="numbers">
                <h3>{data.current}</h3>
                { (data.difference != null && data.difference > 0) ? (loadingPrevious ? <Loader active size="tiny" inline className="difference" /> :
                formatDifference(data.difference)) :<></> }
            </div>
            </>
        }
            
    </div>;
}