// oobh  i got plany off time.
let lastInMonthLookupTable = new Map([
    [0, 31],
    [1, 28],
    [2, 31],
    [3, 30],
    [4, 31],
    [5, 30],
    [6, 31],
    [7, 31],
    [8, 30],
    [9, 31],
    [10, 30],
    [11, 31]
]);
export function isLastInMonth(date : Date) : boolean {
    if (!lastInMonthLookupTable.has(date.getMonth())) return false;
    return lastInMonthLookupTable.get(date.getMonth()) === date.getDate();
}

export function lastOfMonth(date : Date) : Date {
    var dateCopy = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return dateCopy;
}