import { Transaction } from "../interfaces/Transaction";
import { Placeholder, Table } from 'semantic-ui-react';

interface Props {
    sales: Transaction[];
    loading: boolean;
}

export interface TransactionSummaryRow {
    title?: string;
    priceMin: number;
    priceMax: number;
    url: string;
    sales: number;
    total: number;
    partner: string;
}

export function AggregatedTable({sales, loading}: Props) {
    let summaryMap = new Map<string, TransactionSummaryRow>();
    sales.forEach(sale => {
        if (summaryMap.has(sale.url)) {
            summaryMap.get(sale.url)!.sales += 1;
            summaryMap.get(sale.url)!.total += sale.price/100;
            summaryMap.get(sale.url)!.priceMax = Math.max(summaryMap.get(sale.url)!.priceMax, sale.price/100);
            summaryMap.get(sale.url)!.priceMin = Math.min(summaryMap.get(sale.url)!.priceMin, sale.price/100);
        } else {
            summaryMap.set(sale.url, {
                title: sale.title,
                priceMin: sale.price/100,
                priceMax: sale.price/100,
                url: sale.url,
                sales: 1,
                total: sale.price/100,
                partner: sale.partner
            })
        }
    });
    let summary = Array.from(summaryMap.values());
    summary.sort((a, b) => b.total - a.total);

    return <div className="tableWrapper"><Table celled striped unstackable>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Titel</Table.HeaderCell>
                <Table.HeaderCell>Pris SEK</Table.HeaderCell>
                <Table.HeaderCell>Antal köp</Table.HeaderCell>
                <Table.HeaderCell>Summa SEK</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
        {
            loading ?
                <Table.Row>
                    <Table.Cell></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                    <Table.Cell><Placeholder><Placeholder.Line /></Placeholder></Table.Cell>
                </Table.Row>
            :
            summary.map((sale, index) => {
                return <Table.Row key={sale.url}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell><a href={sale.url} target="_blank" rel="noreferrer">{sale.title || sale.url}</a></Table.Cell>
                    <Table.Cell>{sale.priceMax === sale.priceMin ? sale.priceMax : (sale.priceMin + "-" + sale.priceMax)}</Table.Cell>
                    <Table.Cell>{sale.sales}</Table.Cell>
                    <Table.Cell>{Math.round(sale.total*100)/100}</Table.Cell>
                </Table.Row>;
            })
        }
        </Table.Body>
    </Table></div>;
}